@import url("/src/comp/Colors.css");
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

#ContactPage{
    background-color: var(--backGround);
}


#ContactLanding{
    position: relative;
    text-align: center;
}

#ContactLanding img{
    width: 100%;
    height: calc(100vh - 100px);
    object-fit: cover;
}

#ContactLanding h2{
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Colus', serif;
    color: var(--white);
    
    /* backdrop-filter: blur(3px); */
    font-weight: 500;
    font-size: 12vh;
    padding: 15px;
}


/*--------------------------------------------*/

#ContactInfo{
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 1fr;
    grid-template-rows: minmax(70vh, max-content);
    padding-bottom: 30px;
    gap: 10px;
}

#ContactMapDiv{
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    
}

#ContactMapDiv iframe{
    width: 100%;
    height: 100%;
}

#ContactData{
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    font-family: 'Playfair Display', serif;
    background-color: var(--white);
}

#ContactData h3{
    font-size: 5vh;
}
#ContactData p{
    font-size: 1.5rem;
}


@media only screen and (max-width: 1000px) {
    
    #ContactLanding{
        width: 100%;
        height: 100vh;
    }

    #ContactLanding img{
        width: 100%;
        height: 100vh;
        object-fit: cover;
    }
    #ContactLanding h2{
        font-size: 3rem;
    }

    #ContactInfo{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }
    #ContactMapDiv{
        min-height: 50vh;
        width: 90vw;
    }

    #ContactData{
        width: 90vw;
    }
    


}