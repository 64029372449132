@import url("/src/comp/Colors.css");


@media only screen and (min-width: 1000px) {

    #NavContainer{
        position: sticky;
        display: inline-block;
        justify-self: center;
        min-width: 1100px;
        max-width: 1300px;
        height: max-content;
    }
    
    #NavDivider{
        width: 100%;
        padding-top: 1px;
    }
    
    #NavDiv{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
    }
    
    .logos img{
        height: 50px;
        width: auto;
        border-bottom: solid 2px transparent;
        filter: invert(93%) sepia(12%) saturate(7435%) hue-rotate(325deg) brightness(89%) contrast(84%);
        
    }
    
    #MenuBurger{
        display: none;
    }
    
    .logos img:hover{
        transition: 0.3s;
        cursor: pointer;
        filter: invert(93%) sepia(12%) saturate(7435%) hue-rotate(325deg) brightness(89%) contrast(84%);
        border-bottom: solid 2px black;
    }
    
    .links{
        position: static;
        display: flex;
        align-items: center;
        gap: 20px;
        padding-right: 20px;
    }
    
    .links p{
        padding: none;
        margin: none;
        font-size: 25px;
        color: var(--gunPowder);
        border-bottom: solid 2px transparent;
        transition: 0.3s;
    }
    
    .links p:hover{
        color: var(--primary);
        border-bottom: solid 2px var(--primary);
        cursor: pointer;
        transition: 0.3s;
    }
    
    .links img{
        height: 40px;
        position: relative;
        top: 3px;
        padding-bottom: 3px;
        border-bottom: solid 2px transparent;
    }
    
    .links img:hover{
        transition: 0.3s;
        border-bottom: solid 2px black;
        cursor: pointer;
    }
    
    #OrderNav{
        border: solid 2px var(--primary);
        color: var(--primary);
        font-weight: 500;
        border-radius: 10px;
        padding: 3px 7px 3px 7px;
    }
    
    #OrderNav:hover{
        background-color: var(--primary);
        color: var(--white);
    }

    #MobileMenuDiv{
        display: none;
    }
}

@media only screen and (max-width: 1000px) {
    #NavContainer{
        display: none;
    }
    #MobileMenuDiv{
        position: fixed;
        width: 100%;
        height: auto;
        top: 0;
        left: 0;
        z-index: 10;
    }

    #MobileMenuMainBar{
        height: 5vh;
        width: 100%;
        background-color: white;
        padding: 10px 0 10px 0;

        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    #MobileMenuMainBar img{
        height: 50px;
        filter: invert(93%) sepia(12%) saturate(7435%) hue-rotate(325deg) brightness(89%) contrast(84%);
    }
    #MobileMenuMainBar svg{
        height: 50px;
        margin-right: 10px;
    }
    #MobileMenuMainBar:hover svg{
        cursor: pointer;
    }
    #MobileToggleMenu{
        position: fixed;
        background-color: white;
        height: 100%;
        width: 100%;

        display: flex;
        flex-direction: column;
    }
    #MobileLinks p{
        font-size: 2.5rem;
        font-weight: 500;
        padding-right: 15px;
        font-family: 'Playfair Display', serif;
    }

    #MobileToggleMenu img{
        height: 100px;
        padding-top: 50px;
       
    }


}
