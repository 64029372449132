@import url("/src/comp/Colors.css");
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

#AboutPage{
    background-color: var(--backGround);
    padding-bottom: 1vh;
}

#AboutSection-I img:hover{
    transform: scale(1.03);
    border-radius: 10px;
    transition: 0.3s;
}

#AboutSection-II img:hover{
    transform: scale(1.03);
    border-radius: 10px;
    transition: 0.3s;
}

#AboutPage img{
    transition: 0.3s;
}



/*----------------------------------------------*/
#AboutLanding{
    position: relative;
    text-align: center;
    margin-bottom: 10px;
}

#AboutLanding img{
    width: 100%;
    height: 90vh;
    object-fit: cover;
}

#AboutLanding h2{
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);

    
    font-family: 'Colus', serif;
    font-size: 12vh;
    font-weight: 600;
    /* backdrop-filter: blur(3px); */
    color: white;
}
/*----------------------------------------------*/


#AboutSection-I{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: minmax(80vh, max-content);
    margin-bottom: 10px;
}

#AboutSection-I .imgDiv{
    grid-column: 2 / 4;
    grid-row: 1 / 2;
    width: 100%;
    height: 100%;
}

#AboutSection-I .textDiv{
    grid-column: 4 / 6;
    grid-row: 1 / 2;
    padding: 3vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: rgb(255, 254, 191);
}

#AboutSection-I .textDiv h2{
    font-family: 'Playfair Display', serif;
    font-size: 3rem;
}

#AboutSection-I .textDiv p{
    font-family: 'Playfair Display', serif;
    font-size: 1.5rem;
    text-align: left;
}

#AboutSection-I img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/*----------------------------------------------*/

#AboutSection-II{
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 1fr;
    grid-template-rows: minmax(80vh, max-content);
}

#AboutSection-II .imgDiv{
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    
    display: flex;
    gap:5px
}

#AboutSection-II .textDiv{
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    min-width: 400px;
    padding: 3vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: rgb(255, 224, 224);

}

#AboutSection-II img{
    width: 50%;
    height: 100%;
    object-fit: cover;
}

#AboutSection-II .textDiv h2{
    font-family: 'Playfair Display', serif;
    font-size: 3rem;
}

#AboutSection-II .textDiv p{
    font-family: 'Playfair Display', serif;
    font-size: 1.5rem;
    text-align: left;
}

@media only screen and (max-width: 1000px) {
    
    #AboutLanding{
        width: 100%;
        height: 100vh;
    }

    #AboutLanding img{
        width: 100%;
        height: 100vh;
        object-fit: cover;
    }
    #AboutLanding h2{
        font-size: 3rem;
    }

    #AboutSection-I{
        display: flex;
        flex-direction: column-reverse;
    }
    #AboutSection-I div, #AboutSection-I img{
        min-height: 45vh;
    }

    /*------------------------------------------------ */

    #AboutSection-II{
        display: flex;
        flex-direction: column;
        max-width: 100%;
        max-height: fit-content;
    }
    #AboutSection-II div, #AboutSection-II img{
        min-height: 45vh;
    }
    #AboutSection-II .textDiv{
        word-wrap: normal;
        margin: 0;
        padding: 5px;
    }

    #AboutSection-II .textDiv p{
        font-size: 1.5rem;
        overflow-x: hidden;
    }

    #AboutSection-II img{
        width: 50%;
    }


}