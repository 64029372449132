#loadingDiv{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgb(41, 41, 41);
    z-index: 10;
    
    display: flex;
    justify-content: center;
    align-items: center;

    opacity: 1;
    transition: 1s;
}

.fade-out{
    background-color: rgba(41, 41, 41, 0);
    opacity: 0;
  }

#loadingDiv img{
    width: 20vh;
    height: 20vh;
}

@keyframes fadeCompOut {
    from{
        opacity: 1;
    }
    to{
        opacity: 0;
    }
}