@import url("/src/comp/Colors.css");

form{
    width: 100%;
    max-width: 900px;
    height: auto;
    position: absolute;    
    top: 50%;
    left: 50%;

    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;


    color: white;
    font-weight: 500;
    transform: translate(-50%, -50%);
    backdrop-filter: blur(10px);
    border: solid 2px rgb(92, 92, 92);
    border-radius: 30px;
    padding: 30px;
}

form *{
    font-family: 'Playfair Display', serif;
    font-weight: 500;
    font-size: 1.1rem;
}

form label{
    color: var(--primary);
}

form input{
    height: 30px;
    width: 100%;
    border-radius: 5px;
}

form textarea{
    width: 100%;
    height: 200px;
    resize: none;
    border-radius: 5px;
}

.btn{
    align-self: center;
    font-weight: 500;
    width: 20%;
    height: 40px;
    background-color: rgb(255, 255, 255);
}

.btn:hover{
    cursor: pointer;
    transition: 0.3s;
    color: var(--primary);
}

.success{
    align-self: center;
    color: rgb(105, 201, 105);
    opacity: 100%;
    font-size: 1rem;
    transition: 1s;
}

@media only screen and (max-width: 1000px) {
    form{
        width: 90%;
    }
}