@import url("/src/comp/Colors.css");

@media only screen and (min-width: 1001px) {
  .lang-switcher{
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 85px;
    bottom: 10px;
    left: 5vw;
    z-index: 3;
  }
  
  .lang-switcher{
    padding: 0px 10px 0 10px;
    background-color: var(--white);
    border-radius: 10px;
    border: solid 1px var(--dGrey);
  }
  
  .lang-switcher:hover{
    background-color: rgb(255, 240, 240);
    transition: 0.25s;
    cursor: pointer;
  }
  
  .lang-switcher p{
    font-weight: 500;
    font-size: 1rem;
  }
  
  .lang-switcher img{
    height: 30px;
    width: 40px;
    object-fit: fill;
    border: solid 1px var(--dGrey);
    border-radius: 5px;
  }
  
  .selected-div, .dropdown-inner-div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }
  
  .dropdown-inner-div:hover{
    background-color: var(--white);
  }
  
  .selected-div{
    color: var(--gunPowder);
  }
}

@media only screen and (max-width: 1000px) {
  .lang-switcher{
    position: fixed;
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 5px;
    bottom: 10px;
    left: 3vw;
    z-index: 3;
  }
  
  .lang-switcher{
    padding: 5px;
    background-color: var(--white);
    border-radius: 10px;
    border: solid 1px var(--dGrey);
  }
  
  .lang-switcher:hover{
    background-color: rgb(255, 240, 240);
    transition: 0.25s;
    cursor: pointer;
  }

  .language-dropdown{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .lang-switcher p{
    display: none;
  }
  
  .lang-switcher img{
    height: 30px;
    width: 40px;
    object-fit: fill;
    border: solid 1px var(--dGrey);
    border-radius: 5px;
  }
  
  .selected-div, .dropdown-inner-div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }
  
  .dropdown-inner-div:hover{
    background-color: var(--white);
  }
  
  .selected-div{
    color: var(--gunPowder);
  }
}





