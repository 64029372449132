@import url("/src/comp/Colors.css");
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

#HomePage{
    background-color: var(--backGround);
    padding-bottom: 50px;
}

#HomeLanding{
    position: relative;
    text-align: center;
}

#HomeLanding img{
    width: 100%;
    height: 90vh;
    object-fit: cover;
    border-top: solid 3px var(--third);
    border-bottom: solid 3px var(--third);
}

.landingText{
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);

    justify-self: center;

    padding: 15px;
    border-radius: 15px;
    color: rgb(255, 255, 255);
}

.landingText h1, .landingText h2{
    font-family: 'Colus', serif;
    font-size: 12vh;
    font-weight: 600;
    /* backdrop-filter: blur(1px); */
}

/*--------------------------------------------*/

#HomeContent{ 
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: minmax(45vh, max-content) minmax(45vh, max-content);
    grid-gap: 3px;
    padding-bottom: 40px;
}

#HomeContent img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: 0.1s;
}

#HomeContent img:hover{
    transform: scale(1.05);
    transition: 0.3s;
    border-radius: 10px;
}

.HomeImgI{
    grid-column: 2 / 4;
    grid-row: 1 / 2;
    width: 100%;
    height: 100%;
}

.HomeImgII{
    grid-column: 3 / 4;
    grid-row: 2 / 3;
    width: auto;
    height: auto;
}

.HomeImgIII{
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    width: auto;
    height: auto;
}

.HomeDivText{
    grid-column: 4 / 6;
    grid-row: 1 / 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--primary);
    color: white;
    padding: 5vh 5vh 5vh 5vh;
}

.HomeDivText h2{
    font-family: 'Playfair Display', serif;
    font-weight: 500;
    font-style: italic;
    font-size: 6vh;
}

.HomeDivText p{
    font-family: 'Playfair Display', serif;
    font-weight: 300;
    font-size: 2rem;
    text-align: left;
}

.HomeDivText h3{
    font-family: 'Playfair Display', serif;
    font-weight: 500;
    font-style: italic;
    font-size: 2rem;
    border-bottom: solid 2px white;
    transition: 0.3s;
}

.HomeDivText h3:hover{
    transform: scale(1.1);
    transition: 0.3s;
    cursor: pointer;
}

/*--------------------------------------------*/

#HomeMenu{
    display: grid;
    grid-template-columns: 50px 4fr 50px;
    grid-row: auto auto minmax(80vh, max-content);
}

#menuLogo{
    grid-column: 2/3;
    grid-row: 1/2;
    filter: invert(93%) sepia(12%) saturate(7435%) hue-rotate(325deg) brightness(89%) contrast(84%);
    width: 50vw;
    justify-self: center;
}

#HomeMenu h2{
    grid-column: 2/3;
    grid-row: 2/3;

    font-family: 'Playfair Display', serif;
    font-size: 5rem;
    font-weight: 500;
}

#menuImg{
    grid-column: 2/3;
    grid-row: 3/4;
    max-width: 1400px;
    justify-self: center;
    border: solid 3px var(--primary);
    transition: 0.3s;
}

#menuImg:hover{
    transform: scale(1.1);
    transition: 0.3s;
}

@media only screen and (max-width: 1000px) {
    
    #HomeLanding{
        width: 100%;
        height: 100vh;
    }

    #HomeLanding img{
        width: 100%;
        height: 100vh;
        object-fit: cover;
    }

    .landingText h1, .landingText h2{
        font-size: 3rem;
    }

    #HomeContent{
        display: flex;
        flex-direction: column-reverse;
    }
    
    #HomeMenu{
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #menuLogo{
        width: 400px;
        filter: invert(93%) sepia(12%) saturate(7435%) hue-rotate(325deg) brightness(89%) contrast(84%);
    }
    #HomeMenu h2{
        font-size: 3rem;
        border-bottom: solid 2px black;
    }
    #menuImg{
        width: 100%;
    }


    
}