@media only screen and (min-width: 1001px) {
    footer{
        width: 100%;
        height: max-content;
        background-color: rgb(51, 51, 51);
        display: flex;
        justify-content: center;
    }
    
    footer *{
        color: white;
    }
    
    .footerContainer{
        display: flex;
        max-width: 1200px;
        justify-content: space-between;
        gap: 25px;
    }
    
    .footerItem{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        gap:10px
    }
    
    .footerItem svg{
        position: relative;
        bottom: 1px;
    }
    
    .footerItem p{
        font-size: 1rem;
    }
    
    .footerItem a{
        text-decoration: none;
    }

}

@media only screen and (max-width: 1000px) {
    footer{
        width: 100%;
        height: fit-content;
        background-color: rgb(51, 51, 51);
        padding: 10px 0 10px 0;
    }

    footer *{
        color: white;
    }

    .footerContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .footerItem{
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .footerItem svg{
        padding-right: 10px;
    }


}
