@import url("/src/comp/Colors.css");
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

#OrderPage{
    min-height: 90vh;
    height: fit-content;
}

#OrderLanding img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(0px);
    position: relative;
    text-align: center;
}

@media only screen and (max-width: 1000px) {

    #OrderLanding{
        height: 1000px;
    }

    #OrderLanding img{
        object-fit: cover;
    }

    
}
